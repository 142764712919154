<template>
  <div>
    <div class="container">
      <div class="search-tab-container is-flex pt-3 mt-5 pb-3">
        <router-link class="search-tab" :class="{'selected-tab':searchType === 'web'}" :to="`/results?q=${query}`">Web</router-link>
        <router-link class="search-tab" :class="{'selected-tab':searchType === 'images'}" :to="`/results/images/?q=${query}`">Images</router-link>
        <router-link class="search-tab" :class="{'selected-tab':searchType === 'video'}" :to="`/results/video/?q=${query}`">Videos</router-link>

        <div class="latest">
          <router-link class="search-tab" :class="{'selected-tab':searchType === 'news'}" :to="`/results/news/?q=${query}`">Latest</router-link>
          <span class="tooltip">See alternate search results</span>
        </div>
      </div>
    </div>

    <div :class="{container: searchType!=='images'}">

      <div class="mb-6 mt-5">
        <cse-results 
          ref="gcse"
          v-show="selectedSource === sources.EXT"
          :search-type="searchType"
          :query="query"
        />

        <internal-web-results 
          ref="internal"
          v-show="selectedSource === sources.INT" 
          @no-results="showFallBackResults"
        />

        <video-results 
          ref="video" 
          v-show="selectedSource === sources.VID" 
        />

      </div>
    </div>

  </div>

</template>

<script>
import CseResults from '@/components/CseResults'
import InternalWebResults from '@/components/InternalWebResults'
import VideoResults from '@/components/VideoResults'

export default {
  name: "Results",
  
  props:{
    searchType: {
      type: String,
      default: 'web'
    },
    query: {
      type: String,
      default: null
    }
  },
  
  data: () => ({
    loading: false,
    selectedSource: null,
    sources: {
      INT: 'internal',
      EXT: 'gcse',
      VID: 'video'
    }
  }),
  
  components: { CseResults, InternalWebResults, VideoResults },

  methods: {
    showFallBackResults(){
      this.selectedSource = this.sources.EXT;
      this.$refs.gcse.renderCSE(this.query, this.searchType);
    },
    setSource(){
      switch (this.searchType) {
        case 'images':
          this.selectedSource = this.sources.EXT;
          this.$refs.gcse.renderCSE(this.query, this.searchType);
          break;
        case 'video':
          this.selectedSource = this.sources.VID;
          this.$refs.video.renderResults(this.query);
          break;
        case 'news': 
          this.selectedSource = this.sources.EXT;
          this.$refs.gcse.renderCSE(this.query, this.searchType);
          break;
        default:
          this.selectedSource = this.sources.INT;
          this.$refs.internal.renderResults(this.query);
      }
    }
  },

  mounted(){
    this.setSource();
  },

  watch:{
    $route(){
      this.setSource();
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_vars.scss';

.search-tab{
  color: $tertiary;
  margin-right: 21px;
  font-size: 21px;
  font-weight: 500;
}
.selected-tab{
  color: $secondary;
  font-weight: 600;
  border-bottom: 3px solid $secondary;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 2;
}

.latest:hover .tooltip {
  visibility: visible;
}
</style>

