<template>
    <div>
        <article class="media" v-for="i in bones" :key="i" style="max-width:768px">
            <div class="media-content">
                <div class="content">
                    <p>
                        <b-skeleton height="30px" width="90%"></b-skeleton>
                        <b-skeleton height="10px" width="200px"></b-skeleton>
                        <b-skeleton height="10px" width="80%"></b-skeleton>
                        <b-skeleton height="10px" width="90%"></b-skeleton>
                        <b-skeleton height="10px" width="50%"></b-skeleton>
                    </p>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    name: "WebSearchSkeleton",
    data:() => ({
        bones: 5
    })
}
</script>
